<script setup lang="ts">
import {store} from '../../store/Store';

const props = defineProps<{ title: string, extraClass: string }>();

function setLeverage(leverage: number) {
  store.leverage = leverage;
}

function getClass(leverageOption: number)
{
  if(leverageOption == store.leverage)
  {
    return "leverage_btn highlighted";
  }
  else {
    return "leverage_btn";
  }
}

const leverageOptions: number[] = [1, 2, 3, 5, 10]

</script>

<template>
  <div :class="['container-leverage-block',props.extraClass]">
    <div class="alice-leverage-row">
      <div v-if="props.title" style="float: left" class="alice-leverage-title">{{ props.title }}</div>
      <div style="float: right;">
        <o-button name="leverage-option" v-for="leverageOption in leverageOptions" :class="getClass(leverageOption)" :id="'leverage-' + leverageOption" rounded outlined @click="setLeverage(leverageOption)">{{leverageOption}}x</o-button>
      </div>
    </div>
  </div>
</template>

<style scoped>

.container-leverage-block {
  width: 100%;
  height: 40px;
  margin: 30px 0px 0px 5px;
}

.alice-leverage-row {
  display: flex;
  justify-content: space-between;
  margin-top: 4px;
}

.alice-leverage-title {
  display: inline-flex;
  font-family: WorkSans;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--limit-text-colour);
}

.leverage_btn{
  margin-right: 12px;
}

.highlighted {
  background-color: var(--button-background-colour);
  color: white;
}
</style>
